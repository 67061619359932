import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import backIcon from "../../assets/icons/back-button.svg";
import themeColors from "../../assets/stylesheets/_var.scss";
import "../stylesheets/manageApi.scss";
import AuthorizedHeader from "../../components/layouts/AuthorizedHeader";
import SelectedServiceComponent from "../../features/ManageApi/SelectedService/components/SelectedServiceComponent";
import axios from "../../api/axios";
import ComingSoon from "../../features/Placeholders/ComingSoon";
import LocalStorage from "../../utils/LocalStorgae";

export default function ManageApiService() {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const language = LocalStorage.getItem("selectedLanguage");

  const selectedService = location?.state?.service || null;
  const [markdownFile, setMarkdownFile] = useState("");
  const [loader, setLoader] = useState(false);

  const handleBack = () => {
    navigate("/manage/api");
  };

  const getMarkdownFile = () => {
    setLoader(true);
    axios
      .get(`/manageApi/getMarkdownFile/${selectedService?.api_service_id}`)
      .then((res) => {
        if (res?.data && res.data.result.length > 0) {
          const markdownUrl = res.data.result[0].markdown_service_file;
          setMarkdownFile(markdownUrl);
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log("error", error);
      });
  };

  useEffect(() => {
    getMarkdownFile();
  }, [selectedService, language]);

  return (
    <div>
      <AuthorizedHeader />
      <div className="dashboard-body">
        <div className="container">
          <div className="flex-start">
            <button
              type="button"
              className="back-button"
              onClick={() => handleBack()}
            >
              <img className="back-icon" src={backIcon} alt="back-icon" />
              <span className="body-heading back-text">
                {t("ManageApi.back")}
              </span>
            </button>
          </div>
          {![3, 4, 8].includes(selectedService?.api_service_id) ? (
            <ComingSoon />
          ) : (
            <div className="bargraph-body background-box">
              <div className="half-column-grid-with-divider full-height">
                <div className="left-column">
                  {/* <div className="full-height"> */}
                  <SelectedServiceComponent selectedService={selectedService} />
                  {/* </div> */}
                </div>
                <div className="divider" />
                <div className="right-column">
                  <div className="body-heading">
                    {t("ManageApi.connectionInstructions")}
                  </div>
                  <div className="markdown-container">
                    {loader && (
                      <Box className="loader-box">
                        <CircularProgress
                          sx={{ color: themeColors.primaryColor }}
                        />
                      </Box>
                    )}
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                      {markdownFile}
                    </ReactMarkdown>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
