/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable */

import { isMobile } from "react-device-detect";
import { useEffect, useRef, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { MenuItem } from "@mui/material";
import { Tooltip } from "react-tooltip";
import PropTypes from "prop-types";
import BarGraph from "./components/BarGraph";
import PopUpWindow from "../../PopUpWindow";
import {
  convertToK,
  convertToPercent,
  insightsDiff
} from "../../../utils/dataFilter";
import themeColors from "../../../assets/stylesheets/_var.scss";
import ChartHeader from "../../ChartHeader/ChartHeader";
import SearchBar from "../../inputElements/Input/SearchBar";
import { useTranslation } from "react-i18next";

export default function Index({
  loading,
  title,
  subtitle,
  labels,
  links,
  dashboard,
  data,
  compareData,
  numBars,
  columnData,
  isCompare,
  filterValues,
  selectedRange,
  search,
  handleSearch,
  limitedFilteredData,
  setLimitedFilteredData,
  hideComponent,
  pageUrlDataNew
}) {
  const { tooltip, values } = data ?? {};
  const limitedLabels = labels?.slice(0, numBars); // Get the first 'numBars' labels
  const limitedData = values?.slice(0, numBars);
  const limitedCompareData = compareData?.slice(0, numBars) ?? [];

  const { t } = useTranslation();

  const onHover = (event, chartElement) => {
    const { native } = event;
    native.target.style.cursor = chartElement[0] ? "pointer" : "default";
  };

  const [togglePopUp, setTogglePopUp] = useState(false);
  const [filterGear, setFilterGear] = useState(false);
  const [showGear, setShowGear] = useState(false);
  const menuFilterRef = useRef(null);

  const [columns, setColumns] = useState([]);

  const mobileDevice = isMobile ? true : false;

  const getSelectedColor = () => {
    if (!Object.keys(filterValues).includes(title))
      return labels?.map(() => themeColors.primaryColor4);

    return labels?.map((ele) => {
      if (filterValues[title].includes(ele)) return themeColors.primaryColor4;
      return `${themeColors.primaryColor4}80`;
    });
  };
  const getCompareSelectedColor = () => {
    if (!Object.keys(filterValues).includes(title))
      return labels?.map(() => `${themeColors.secondaryColorShade2}EE`); // "rgba(224, 186, 128, 1)"

    return labels?.map((ele) => {
      if (filterValues[title].includes(ele))
        return `${themeColors.secondaryColorShade2}EE`;
      return `${themeColors.secondaryColorShade2}55`;
    });
  };
  const customLabel = (tooltipItem) => {
    return `${tooltip}: ${tooltipItem.formattedValue}`;
  };

  const handleChange = (e) => {
    const val = parseInt(e.target.value, 10);

    if (columns.includes(val)) {
      setColumns(columns.filter((ele) => ele !== val));
    } else {
      setColumns([...columns, val]);
    }

    // Check if the checkbox is checked or unchecked
  };

  const options = {
    animation: false,
    indexAxis: "y",
    maintainAspectRatio: false,
    devicePixelRatio:
      title === t("analyticsPage.pagesTitle") && togglePopUp
        ? 2
        : themeColors.devicePixelRatio,
    elements: {
      bar: {
        borderWidth: 2
      }
    },
    scales: {
      x: {
        display: false,
        grid: {
          display: false
        },
        ticks: {
          display: false
        }
      },
      y: {
        display: isCompare ? 1 : 0,
        grid: {
          display: false
        },
        ticks: {
          display: false
        }
      }
    },
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        cornerRadius: 10,
        backgroundColor: themeColors.primaryText,
        displayColors: false,
        padding: "10",
        titleFont: {
          family: themeColors.fontQuickSand,
          size: "16"
        },
        bodyFont: {
          family: themeColors.fontQuickSand,
          size: "16"
        },
        callbacks: {
          label: customLabel,
          labelTextColor: () => {
            return themeColors.primaryColor3;
          }
        }
      }
    },
    onHover
  };
  const barGraphData = isCompare
    ? {
        labels: limitedLabels,
        datasets: [
          {
            label: title,
            backgroundColor: getCompareSelectedColor(),
            borderColor: getCompareSelectedColor(),
            borderWidth: 0.9,
            hoverBackgroundColor: getCompareSelectedColor(),
            hoverBorderColor: getCompareSelectedColor(),
            data: [...(limitedData || [])],
            categoryPercentage: 0.8
          },
          {
            label: title,
            backgroundColor: getSelectedColor(),
            borderColor: getSelectedColor(),
            borderWidth: 1,
            hoverBackgroundColor: getSelectedColor(),
            hoverBorderColor: getSelectedColor(),
            data: [...(limitedCompareData || [])],
            categoryPercentage: 0.8
          }
        ]
      }
    : {
        labels: limitedLabels,
        datasets: [
          {
            label: title,
            backgroundColor: getSelectedColor(),
            borderColor: getSelectedColor(),
            borderWidth: 0.9,
            hoverBackgroundColor: getSelectedColor(),
            hoverBorderColor: getSelectedColor(),
            data: [...(limitedData || [])],
            categoryPercentage: 0.9,
            maxBarThickness: 50
          }
        ]
      };

  const dataForPopUp = isCompare
    ? {
        labels: labels?.length > 500 ? labels.slice(0, 500) : labels,
        datasets: [
          {
            ...barGraphData.datasets[0],
            maxBarThickness: 35,
            data: [
              ...(values?.length > 500 ? values.slice(0, 500) : values || [])
            ]
          },
          {
            ...barGraphData.datasets[1],
            maxBarThickness: 35,
            data: [
              ...(compareData?.length > 500
                ? compareData.slice(0, 500)
                : compareData || [])
            ]
          }
        ]
      }
    : {
        labels: labels?.length > 1000 ? labels.slice(0, 1000) : labels,
        datasets: [
          {
            ...barGraphData.datasets[0],
            barThickness: labels?.length < 600 ? 40 : 25,
            maxBarThickness: labels?.length < 600 ? 50 : 30,
            data: [
              ...(values?.length > 1000 ? values.slice(0, 1000) : values || [])
            ]
          }
        ]
      };

  const handleOutsideClick = (event) => {
    if (
      menuFilterRef.current &&
      !menuFilterRef.current.contains(event.target)
    ) {
      setFilterGear(false);
    }
  };

  const handleButtonMouseDown = (event) => {
    // Prevent the mousedown event from reaching the document
    event.stopPropagation();
  };

  useEffect(() => {
    if (filterGear) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [filterGear]);

  useEffect(() => {
    if (dashboard === "pms") {
      setColumns([columnData[0]?.id]);
    } else {
      setColumns(columnData.map((ele) => ele.id));
    }
    if (columnData?.length > 1) {
      setShowGear(true);
    }
  }, [columnData]);

  const loader = (
    <div
      style={{
        height: "500px",
        display: "grid",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <TailSpin
        height="45"
        width="45"
        color={themeColors.primaryColor}
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
        visible
      />
    </div>
  );

  const styleToHidecomponent = {
    pointerEvents: "none",
    opacity: "0.2"
  };

  const ChartData = (
    <div className="bargraph-body background-box">
      <ChartHeader
        title={
          dashboard === "isichat" && selectedRange
            ? `${title} ${selectedRange}`
            : title
        }
        togglePopUp={togglePopUp}
        setTogglePopUp={setTogglePopUp}
        showGear={showGear}
        filterGear={filterGear}
        setFilterGear={setFilterGear}
        handleButtonMouseDown={handleButtonMouseDown}
        search={search}
        onSearch={handleSearch}
        limitedFilteredData={limitedFilteredData}
        setLimitedFilteredData={setLimitedFilteredData}
        pageUrlDataNew={pageUrlDataNew}
      />
      <div className="flex-end">
        <div>
          {search && (
            <div
              style={{
                marginLeft: "5%",
                marginTop: "3%"
              }}
              className="search-bar"
            >
              <SearchBar
                placeholder={t("common.searchPages")}
                // height="2rem"
                onSearch={handleSearch}
              />
            </div>
          )}
        </div>
      </div>
      {loading ? (
        loader
      ) : (
        <>
          {filterGear && (
            <div className="menuFilter" ref={menuFilterRef}>
              {columnData.map((ele) => (
                <MenuItem value={ele.id} onClick={handleChange}>
                  <input
                    type="checkbox"
                    checked={
                      columns.findIndex((column) => column === ele.id) > -1
                    }
                    className="custom-checkbox"
                    value={ele.id}
                    id={`checkbox-${ele.id}`}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor={`checkbox-${ele.id}`}
                    className="custom-checkbox-label"
                  >
                    <div className="checkmark" />
                    <div className="menuItemList">{ele.name}</div>
                  </label>
                </MenuItem>
              ))}
            </div>
          )}
          <div
            className="flex"
            style={hideComponent ? styleToHidecomponent : {}}
          >
            {isCompare && (
              <div
                className="flex-space"
                style={{
                  width: columnData?.length > 1 ? "22%" : "30%",
                  overflow: "hidden"
                }}
              >
                {labels && (
                  <div>
                    <div className="labels-list">
                      {labels
                        .slice(
                          0,
                          togglePopUp
                            ? labels?.length > 500
                              ? 500
                              : labels.length
                            : numBars
                        )
                        .map((ele, i) => (
                          <div
                            style={
                              ele.length > (links ? 10 : 6)
                                ? {
                                    // width: `20px`,
                                    width: "80%",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    textAlign: "left",
                                    // fontWeight: "550",
                                    fontSize: "2vh"
                                  }
                                : {
                                    textAlign: "left",
                                    // fontWeight: "550",
                                    fontSize: "2vh"
                                  }
                            }
                            data-tooltip-id="tooltip-for-bargraph"
                            data-tooltip-html={
                              links
                                ? `<a href=${links[i]} target="_blank">${links[i]}</a>`
                                : `<span>${labels[i]}</span>`
                            }
                          >
                            {ele}
                          </div>
                        ))}
                    </div>
                    <Tooltip
                      id="tooltip-for-bargraph"
                      clickable
                      className="tooltip-link"
                    />
                  </div>
                )}
              </div>
            )}
            <div
              style={{
                width: "100%",
                marginLeft: "auto",
                height: togglePopUp
                  ? `${
                      (isCompare && labels.length > 500
                        ? 500
                        : labels.length > 1000
                        ? 1000
                        : labels.length) *
                      (labels.length === 1
                        ? 70
                        : isCompare
                        ? 60
                        : labels.length < 600
                        ? 50
                        : 30)
                    }px`
                  : `${
                      numBars *
                      (numBars === 1
                        ? 90
                        : numBars === 2
                        ? 60
                        : isCompare
                        ? 70
                        : 50)
                    }px`
              }}
            >
              <div className="sub-text">{isCompare ? "Graph" : subtitle}</div>

              <BarGraph
                options={options}
                data={togglePopUp ? dataForPopUp : barGraphData}
                dashboard={dashboard}
                isCompare={isCompare}
                filterValues={filterValues}
                selectedRange={selectedRange}
              />
            </div>
            <div className="flex" style={{ marginLeft: "auto" }}>
              {columnData?.map((column) => {
                return (
                  columns.includes(column.id) && (
                    <div className="">
                      <div className="sub-text text-center">{column.name}</div>
                      <div className="visitors-list">
                        {column?.diff
                          ?.slice(
                            0,
                            togglePopUp
                              ? isCompare && column?.diff?.length > 500
                                ? 500
                                : column?.diff?.length > 1000
                                ? 1000
                                : column?.diff?.length
                              : numBars
                          )
                          ?.map((ele, i) => (
                            <div
                              className="flex-column-between"
                              style={{
                                width: "100px"
                              }}
                            >
                              {column.euro && `${ele} €`}
                              {column.number && convertToK(ele)}
                              {column.percent && convertToPercent(ele)}
                              {isCompare &&
                                insightsDiff(column.first[i], column.second[i])}
                            </div>
                          ))}
                      </div>
                    </div>
                  )
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );

  if (!togglePopUp) {
    return ChartData;
  }

  return <PopUpWindow className="bargraph">{ChartData}</PopUpWindow>;
}

Index.propTypes = {
  loading: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  dashboard: PropTypes.string,
  pageUrlDataNew: PropTypes.array,
  labels: PropTypes.oneOfType([PropTypes.array]).isRequired,
  links: PropTypes.oneOfType([PropTypes.array]).isRequired,
  data: PropTypes.oneOfType([Object]).isRequired,
  compareData: PropTypes.oneOfType([PropTypes.array]).isRequired,
  numBars: PropTypes.number,
  columnData: PropTypes.oneOfType([PropTypes.array]).isRequired,
  isCompare: PropTypes.bool,
  filterValues: PropTypes.oneOfType([PropTypes.object]),
  selectedRange: PropTypes.string,
  hideComponent: PropTypes.bool
};
