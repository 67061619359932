/* eslint-disable */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Dialog, IconButton } from "@mui/material";
import sort from "../../../../assets/icons/sort.svg";
import DataTable from "../../../../components/Table/DataTable";
import themeColors from "../../../../assets/stylesheets/_var.scss";
import "../assets/stylesheets/userManagement.scss";
import {
  ButtonDark,
  ButtonLight
} from "../../../../components/inputElements/buttons/MainButton";
import Input from "../../../../components/inputElements/Input/Input";
import MultipleSelect from "../../../../components/inputElements/MultiSelect/MultiSelect";
import GreenIcon from "../../../../assets/icons/status (3).svg";
import YellowIcon from "../../../../assets/icons/status (2).svg";
import roleDesc from "../../../../assets/icons/Frame 7881.svg";
import roleDescGer from "../../../../assets/icons/control_access_ger.svg";
import roleDescIt from "../../../../assets/icons/control_access_it.svg";
import editButton from "../../../../assets/icons/edit.svg";
import deleteButton from "../../../../assets/icons/buttons.svg";
import { getUserManagement, sendInviteUser, updateUser } from "../api/userApi";
import { Toastr } from "../../../../components/Toastr/Toastr";
import axios from "../../../../api/axios";
import DeleteComponent from "../../../../components/DeletePopUp/DeleteComponent";
import WebsiteRoleTableComponent from "./WebsiteRoleTableComponent";
import LocalStorage from "../../../../utils/LocalStorgae";

const roleMappingsNew = {
  en: {
    2: "Collaborator",
    3: "Accountant",
    4: "Reader",
    5: "Collaborator, Accountant",
    6: "Accountant, Reader",
    7: "Admin"
  },
  de: {
    2: "Mitarbeiter",
    3: "Buchhalter",
    4: "Leser",
    5: "Mitarbeiter, Buchhalter",
    6: "Buchhalter, Leser",
    7: "Administrator"
  },
  it: {
    2: "Collaboratore",
    3: "Contabile",
    4: "Lettore",
    5: "Collaboratore, Contabile",
    6: "Contabile, Lettore",
    7: "Amministratore"
  }
};

const dashboardMappingNew = {
  en: {
    1: "Web analytics",
    2: "CRM analytics",
    // 3: "Campaign Management",
    5: "Personas analytics",
    6: "Customer chatdata",
    4: "All Dashboards"
  },
  de: {
    1: "Web-Analytik",
    2: "CRM-Analytik",
    // 3: "Kampagnenmanagement",
    5: "Personas-Analytik",
    6: "Kundendaten aus Chats",
    4: "Alle Dashboards"
  },
  it: {
    1: "Analisi web",
    2: "Analisi CRM",
    // 3: "Gestione Campagne",
    5: "Analisi personas",
    6: "Dati delle chat dei clienti",
    4: "Tutti i Dashboard"
  }
};

function CustomButton() {
  return (
    <IconButton>
      <img src={sort} alt="sort" />
    </IconButton>
  );
}

function LabelInputSet({ children }) {
  return <div className="flex-column">{children}</div>;
}

export default function UserManagement() {
  const { websiteID } = useSelector((state) => state.generalData);
  const [addUserPopUp, setAddUserPopUp] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [selectMultipleRowData, setSelectMultipleRowData] = useState([]);
  const [openRole, setOpenRole] = useState(false);
  const { createdBy: customerId } = useSelector((state) => state.generalData);
  const [addWebsiteRole, setAddWebsiteRole] = useState(false);
  const [addUsers, setAddUsers] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null); // Store selected row data for editing
  const [filteredUserData, setFilteredUserData] = useState([]);
  const [searchFilter, setSearchFilter] = useState("email");

  const selectedLanguage = LocalStorage.getItem("selectedLanguage");

  // States for storing user data
  const [userEmail, setUserEmail] = useState(""); // New state to hold user email
  const [emailError, setEmailError] = useState("");
  const [websiteError, setWebsiteError] = useState("");
  const [selectedWebsites, setSelectedWebsites] = useState([]); // State to store selected websites
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedDashboards, setSelectedDashboards] = useState([]); // State to store selected website dashbards
  const [userWebsitesInfo, setUserWebsitesInfo] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [ownerAdminWebsites, setOwnerAdminWebsites] = useState([]);

  const { websitesList } = useSelector((state) => state.generalData);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const adminOrOwnerWebsites = websitesList?.filter(
    (website) => website.role_id === 1 || website.role_id === 7
  );
  console.log("adminOrOwnerWebsites", adminOrOwnerWebsites);
  const newRoleMapping = roleMappingsNew[selectedLanguage];
  const newDashboardMapping = dashboardMappingNew[selectedLanguage];

  const [selectedUserId, setSelectedUserId] = useState(null);
  console.log("selectedUserId", selectedUserId);

  const handleRowClick = (rowData) => {
    setSelectedRowData(rowData);
    setEditMode(true);
    setSelectedUserId(rowData?.id);
    setAddUserPopUp(true);
    setAddWebsiteRole(true);
  };

  const handleRowSelect = (rowData) => {
    setSelectMultipleRowData(rowData);
  };

  const handleRowDelete = () => {
    console.log(selectMultipleRowData);
    if (selectMultipleRowData !== null && selectMultipleRowData.length > 0) {
      setShowDeletePopup(true);
    } else {
      Toastr.error("Please select a user to delete from the list");
    }
  };

  const getWebsiteName = (websiteId) => {
    const selectedWebsite = adminOrOwnerWebsites?.find(
      (website) => website.id === websiteId
    );
    return selectedWebsite ? selectedWebsite.name : "";
  };

  const getUserRole = async () => {
    const res = await getUserManagement({
      customer_id: customerId,
      website_id: websiteID
    });
    console.log("getUsers", res?.data);
    setAddUsers(res.data.result);
  };

  useEffect(() => {
    getUserRole();
  }, [websiteID]);

  useEffect(() => {
    const merged = [];
    selectedRoles.forEach((role) => {
      if (selectedWebsites.includes(role.website)) {
        const matchingDashboard = selectedDashboards.find(
          (dashboard) => dashboard.website === role.website
        );
        merged.push({
          ...role,
          ...(matchingDashboard
            ? { dashboard_id: matchingDashboard.dashboard_id }
            : {})
        });
      }
    });
    selectedDashboards.forEach((dashboard) => {
      if (selectedWebsites.includes(dashboard.website)) {
        const matchingRole = selectedRoles.find(
          (role) => role.website === dashboard.website
        );
        if (!matchingRole) {
          merged.push({
            website: dashboard.website,
            dashboard_id: dashboard.dashboard_id
          });
        }
      }
    });
    setUserWebsitesInfo(merged);
  }, [selectedRoles, selectedDashboards, selectedWebsites]);

  useEffect(() => {
    if (editMode) {
      const roles = selectedRowData?.websites?.map((selrole) => ({
        website: selrole.website_id,
        role_id: selrole.role_id
      }));
      setSelectedRoles(roles);
    }
  }, [editMode, selectedRowData]);

  useEffect(() => {
    if (editMode) {
      const dashboards = selectedRowData?.websites?.map((seldashboard) => ({
        website: seldashboard.website_id,
        dashboard_id: seldashboard.dashboard_id
      }));
      setSelectedDashboards(dashboards);
    }
  }, [editMode, selectedRowData]);

  useEffect(() => {
    if (editMode) {
      const websites = selectedRowData?.websites?.map((selwebsite) => ({
        website: selwebsite.website_id,
        role_id: selwebsite.role_id,
        dashboard_id: selwebsite.dashboard_id
      }));
      setUserWebsitesInfo(websites);
    }
  }, [editMode, selectedRowData]);

  useEffect(() => {
    const updatedDashboards = selectedDashboards?.map((dashboard) => {
      const matchingRole = selectedRoles?.find(
        (role) => role?.website === dashboard?.website && role?.role_id === 3
      );
      if (matchingRole) {
        return {
          ...dashboard,
          dashboard_id: []
        };
      }
      return dashboard;
    });
    setSelectedDashboards(updatedDashboards);
  }, [selectedRoles]);

  useEffect(() => {
    const hasRole3 = selectedRoles.some((role) => role.role_id === 3);
    if (hasRole3) {
      setSelectedDashboards((prevDashboards) => {
        const updatedDashboards = selectedRoles
          .map((role) => {
            if (role.role_id === 3) {
              const existingDashboard = prevDashboards.find(
                (dashboard) => dashboard.website === role.website
              );
              if (!existingDashboard) {
                return { website: role.website, dashboard_id: [] };
              }
            }
            return null;
          })
          .filter(Boolean);
        return [...prevDashboards, ...updatedDashboards];
      });
    }
  }, [selectedRoles]);

  useEffect(() => {
    const updatedWebsiteIds = adminOrOwnerWebsites?.map((obj) => ({
      website_id: obj?.id
    }));
    setOwnerAdminWebsites(updatedWebsiteIds);
  }, []);

  console.log("ownerAdminWebsites", ownerAdminWebsites);

  const handleClearForm = () => {
    setUserEmail("");
    setSelectedWebsites([]);
    setSelectedRoles([]);
    setSelectedDashboards([]);
    setUserWebsitesInfo([]);
    setAddWebsiteRole(false);
  };

  const confirmDeleteRows = () => {
    console.log(selectMultipleRowData);
    const deleteArray = selectMultipleRowData.map((ele) => {
      return {
        customer_id: ele,
        websites: [{ website_id: websiteID }]
      };
    });
    console.log("deleteArray", JSON.stringify(deleteArray));
    axios
      .post("userManagement/deleteUserManagement", deleteArray)
      .then((res) => {
        console.log(res);
        if (res.data.success === true) {
          getUserRole();
          Toastr.success(t("tagManager.deletedTagsSuccess"));
        }
      });
  };

  const handleAddUserButtonClick = () => {
    handleClearForm();
    setAddUserPopUp(true);
  };

  const validateUserWebsitesInfo = () => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    let state = true;
    if (userEmail === "") {
      state = false;
      Toastr.error("Please enter email address");
    }
    if (userEmail !== "" && !emailPattern.test(userEmail)) {
      Toastr.error("Invalid email address");
    }
    if (
      selectedWebsites.length === 0 ||
      userWebsitesInfo?.length !== selectedWebsites?.length
    ) {
      state = false;
      Toastr.error("Please add roles and dashboards for selected websites");
    }
    userWebsitesInfo.forEach((obj) => {
      if (!("website" in obj) || obj.website == null) {
        state = false;
        Toastr.error("Please add roles and dashboards for selected websites");
      }

      if (!("role_id" in obj) || obj.role_id == null) {
        state = false;
        Toastr.error("Please add roles for selected websites");
      }

      if (
        obj.role_id !== 3 &&
        (!("dashboard_id" in obj) ||
          !Array.isArray(obj.dashboard_id) ||
          obj.dashboard_id.length === 0)
      ) {
        state = false;
        Toastr.error("Dashboards selection is mandatory");
      }
    });
    return state;
  };

  const handleSave = async () => {
    const websitesData = userWebsitesInfo?.map((website) => ({
      role_id: website.role_id,
      website_id: website.website,
      dashboard_id: website.dashboard_id
    }));
    const createUserPayload = {
      email: userEmail,
      // customer_id: customerId,
      websites: websitesData
    };
    setLoading(true);
    console.log("createUserPayload", JSON.stringify(createUserPayload));
    const state = validateUserWebsitesInfo();
    if (state) {
      const res = await sendInviteUser(createUserPayload);
      console.log("res", res);
      if (res.success === true) {
        setLoading(false);
        setEditMode(false);
        handleClearForm();
        setAddUserPopUp(false);
        Toastr.success("User added successfully");
        getUserRole();
      }
    }
    setLoading(false);
  };

  const handleEdit = async () => {
    const websitesData = userWebsitesInfo?.map((website) => ({
      role_id: website.role_id,
      website_id: website.website,
      dashboard_id: website.dashboard_id
    }));

    const createUserPayload = {
      email: userEmail,
      customer_id: customerId,
      websites: websitesData
    };

    console.log("createUserPayload", JSON.stringify(createUserPayload));
    setLoading(true);
    const state = validateUserWebsitesInfo();
    if (state) {
      const res = await updateUser(createUserPayload);
      if (res.success === true) {
        setLoading(false);
        setEditMode(false);
        handleClearForm();
        setAddUserPopUp(false);
        Toastr.success("User updated successfully");
        getUserRole();
      }
    }
    setLoading(false);
  };

  const searchFilters = [
    {
      name: t("userManagement.dataTable.users"),
      value: "email"
    },
    {
      name: t("userManagement.dataTable.sources"),
      value: "website_name"
    },
    {
      name: t("userManagement.dataTable.roles"),
      value: "name"
    }
  ];

  const columns = [
    {
      field: "email",
      headerName: t("userManagement.dataTable.users"),
      flex: 1
    },
    {
      field: "website_name",
      headerName: t("userManagement.dataTable.sources"),
      flex: 1
    },
    {
      field: "name",
      headerName: t("userManagement.dataTable.roles"),
      flex: 1
    },
    {
      field: "is_verified",
      headerName: t("userManagement.dataTable.status"),
      flex: 1,
      renderCell: (params) => (
        <>
          <div className="status-icon">
            {params.value === 1 ? (
              <img src={GreenIcon} alt="Verified" />
            ) : (
              <img src={YellowIcon} alt="Not Verified" />
            )}
          </div>
          <button className="editButton" type="button">
            <img src={editButton} alt="edit" />
          </button>
        </>
      )
    }
  ];

  const rows = addUsers.map((user) => ({
    id: user.customer_id,
    email: user.email,
    website_name: user.websites
      .map((website) => website.website_name)
      .join(", "),
    name: user.websites
      .map((website) => newRoleMapping[website.role_id])
      .join(", "),
    is_verified: user.is_verified,
    websites: user.websites.map((website) => ({
      website_id: website.website_id,
      role_id: website.role_id,
      dashboard_id: website.dashboard_id,
      name: website.name,
      website_name: website.website_name
    }))
  }));

  useEffect(() => {
    if (editMode) {
      setUserEmail(selectedRowData?.email);
    }
  }, [editMode, selectedRowData]);

  const validateEmail = (email) => {
    const regex = /^\S+@\S+\.\S+$/;
    return regex.test(email);
  };

  const handleEmail = (e) => {
    setUserEmail(e);
    if (emailError) {
      setEmailError("");
    }
  };

  const roles = [2, 3, 4, 5, 6, 7];
  const options = [1, 2, 5, 6, 4]; // Add 3 when Campaign Management needs to be displayed in dashboardMappingNew

  console.log("userWebsitesInfoPayload", userWebsitesInfo);
  console.log("selectedRowData", selectedRowData);
  const selectedRoleDesc = () => {
    switch (selectedLanguage) {
      case "de":
        return roleDescGer;
      case "it":
        return roleDescIt;
      default:
        return roleDesc;
    }
  };

  const handleApply = () => {
    if (userEmail === "" || selectedWebsites?.length < 1) {
      if (userEmail === "")
        setEmailError(t("common.emailValidation.insertEmail"));
      if (selectedWebsites?.length < 1)
        setWebsiteError(t("common.pleaseSelectWebsite"));
      return;
    }
    if (validateEmail(userEmail)) {
      setEmailError("");
    } else {
      setEmailError(t("common.emailValidation.invalidEmail"));
      return;
    }
    setAddWebsiteRole(true);
  };

  const handleSearch = (searchTerm) => {
    const filteredResults = [];
    if (searchTerm?.length >= 1) {
      rows?.forEach((user) => {
        const value = user[searchFilter];
        const lowerCaseValue = value?.toLowerCase?.();
        const lowerCaseSearchTerm = searchTerm?.toLowerCase();

        const startsWithCondition =
          lowerCaseValue.startsWith(lowerCaseSearchTerm);

        const includesCondition = lowerCaseValue.includes(lowerCaseSearchTerm);

        const exactMatchCondition = lowerCaseValue === lowerCaseSearchTerm;

        if (startsWithCondition) {
          filteredResults.unshift(user); // Add exact matches to the beginning
        } else if (exactMatchCondition) {
          filteredResults.push(user); // Add starts with matches after exact matches
        } else if (includesCondition) {
          filteredResults.push(user); // Add includes matches last
        }
      });
    } else {
      filteredResults.push(...rows);
    }
    setFilteredUserData(filteredResults);
  };

  useEffect(() => {
    if (selectedWebsites.length > 0) {
      setWebsiteError("");
    }
  }, [selectedWebsites, setSelectedWebsites]);

  return (
    <div className="user-management">
      <div className="header-content flex-between">
        <div className="modules-heading">{t("userManagement.title")}</div>
        <div className="flex">
          {" "}
          {/* <img
            className="delete-multiple"
            src={deleteButton}
            alt="delete"
            onClick={handleRowDelete}
          /> */}
          <button
            className={`delete-multiple ${
              isMobile ? "backgroundSizeMobile" : "backgroundSize"
            }`}
            type="button"
            onClick={handleRowDelete}
          />
          {showDeletePopup && (
            <DeleteComponent
              loading={false}
              onCancel={() => setShowDeletePopup(false)}
              onConfirmDelete={() => {
                setShowDeletePopup(false);
                confirmDeleteRows();
              }}
            />
          )}
          <ButtonDark
            buttonText={t("userManagement.addUser")}
            onClick={() => {
              setEditMode(false);
              handleAddUserButtonClick();
              setSelectedRowData(null);
            }}
          />
        </div>
      </div>

      <div className="table">
        <DataTable
          columns={columns}
          rows={filteredUserData?.length > 0 ? filteredUserData : rows}
          getRowId={(row) => row.id}
          onRowClick={handleRowClick}
          CustomButton={CustomButton}
          onRowsSelect={handleRowSelect}
          tableType={0}
          search
          searchFilters={searchFilters}
          searchFilter={searchFilter}
          setSearchFilter={setSearchFilter}
          handleSearch={handleSearch}
        />
      </div>
      <div>
        <button
          className="role-button"
          type="button"
          onClick={() => setOpenRole(true)}
        >
          {t("userManagement.viewRoleDescriptionDetails")}
        </button>
      </div>
      <Dialog
        open={addUserPopUp}
        onClose={() => {
          handleClearForm();
          setSelectedRowData(null);
          setEditMode(false);
        }}
        maxWidth="md"
        fullWidth
        className="user-management"
      >
        <div className="grid-gap">
          <div>
            <text className="add-edit-user-text">
              {!editMode ? t("userManagement.addUserDialogTitle") : "Edit user"}
            </text>
          </div>
          <div>
            <LabelInputSet>
              <label className="users-labels-text" htmlFor="userEmail">
                {t("userManagement.userEmailAddressLabel")}
              </label>
              {selectedRowData ? (
                <Input
                  type="text"
                  id="userEmail"
                  value={selectedRowData.email}
                  onChange={(e) => handleEmail(e.target.value)}
                  error={emailError}
                />
              ) : (
                <Input
                  type="text"
                  id="userEmail"
                  value={userEmail}
                  onChange={(e) => handleEmail(e.target.value)}
                  error={emailError}
                />
              )}
            </LabelInputSet>
          </div>
          <div>
            <LabelInputSet>
              <label htmlFor="userEmail" className="users-labels-text">
                {t("userManagement.addWebsiteLabel")}
              </label>
              {/* Multi select website dropdown */}
              <MultipleSelect
                names={adminOrOwnerWebsites}
                selectedWebsites={selectedWebsites}
                setSelectedWebsites={setSelectedWebsites}
                // preFilledData={selectedRowData ? selectedRowData : null}
                preFilledData={selectedRowData || null}
                editMode={editMode}
                error={websiteError}
              />
              {/* Multi select website dropdown ends */}
            </LabelInputSet>
          </div>
          {addWebsiteRole && selectedWebsites?.length > 0 && (
            <div>
              <text className="users-labels-text">
                {t("userManagement.dataTable.roles")}
              </text>
              <WebsiteRoleTableComponent
                selectedWebsites={selectedWebsites}
                setSelectedWebsites={setSelectedWebsites}
                getWebsiteName={getWebsiteName}
                roleMapping={newRoleMapping}
                roles={roles}
                dashboardMapping={newDashboardMapping}
                options={options}
                selectedRolesNew={selectedRoles}
                setSelectedRolesNew={setSelectedRoles}
                selectedDashboards={selectedDashboards}
                setSelectedDashboards={setSelectedDashboards}
                websitesList={adminOrOwnerWebsites}
                userWebsitesInfo={userWebsitesInfo}
                setAddWebsiteRole={setAddWebsiteRole}
              />
            </div>
          )}
        </div>
        {!addWebsiteRole && (
          <div className="flex-center marginTop">
            <ButtonLight
              buttonText={t("common.cancelButton")}
              className="margin-right"
              onClick={() => {
                handleClearForm();
                setEditMode(false);
                setAddUserPopUp(false);
                setEmailError("");
                setWebsiteError("");
              }}
            />
            <ButtonDark
              buttonText={t("common.applyButton")}
              onClick={() => {
                handleApply();
              }}
            />
          </div>
        )}
        {addWebsiteRole && (
          <div className="flex-center marginTop">
            <ButtonLight
              buttonText={t("common.cancelButton")}
              className="margin-right"
              onClick={() => {
                handleClearForm();
                setEditMode(false);
                setAddUserPopUp(false);
              }}
            />
            <ButtonDark
              buttonText={t("common.saveButton")}
              onClick={selectedRowData ? handleEdit : handleSave}
              loader={loading}
              disabled={loading}
            />
          </div>
        )}
      </Dialog>
      <Dialog
        open={openRole}
        onClose={() => {
          setOpenRole(false);
        }}
        maxWidth="md"
        fullWidth
        className="user-management"
      >
        <div>
          <div className="flex-between">
            <div className="role-management">
              {`${t("userManagement.note")} 
              ${t("userManagement.roleManagementDetails")}`}
            </div>
            <div className="view-less" onClick={() => setOpenRole(false)}>
              {t("userManagement.close")}
            </div>
          </div>
          <div className="role-desc">{t("userManagement.roleDescription")}</div>
          <div className="role-sub">
            {t("userManagement.cotrolAccessOfActions")}
          </div>
          <img src={selectedRoleDesc()} alt="role" />
        </div>
      </Dialog>
    </div>
  );
}

LabelInputSet.propTypes = {
  children: PropTypes.element
};
