import { configureStore, combineReducers } from "@reduxjs/toolkit";
import slices from "./slices/dataSlice";
import compareSlice from "./slices/compareSlice";
import generalSlice from "./slices/generalSlice";
import PMSSlice from "./slices/dataPMSSlice";
import comparePMSSlice from "./slices/comparePMSSlice";
import PersonasSlice from "./slices/personasSlice";
import isiChatSlices from "./slices/isiChatDataSlice";
import compareISIChatSlice from "./slices/compareISIChatSlice";
import campaignSlice from "./slices/campaignPerformance";

const { dataSlice, dataToCompareSlice } = slices;
const { dataPMSSlice, dataToComparePMSSlice } = PMSSlice;
const { personasDataSlice } = PersonasSlice;
const { isiChatDataSlice, isiChatCompareDataSlice } = isiChatSlices;
const { campaignPerformanceSlice } = campaignSlice;

const rootReducers = combineReducers({
  dashboardData: dataSlice.reducer,
  dataToCompare: dataToCompareSlice.reducer,
  compareData: compareSlice,
  generalData: generalSlice,
  PMSData: dataPMSSlice.reducer,
  PMSdataToCompare: dataToComparePMSSlice.reducer,
  comparePMSData: comparePMSSlice,
  PersonasData: personasDataSlice.reducer,
  isiChatData: isiChatDataSlice.reducer,
  isiChatCompareData: isiChatCompareDataSlice.reducer,
  compareISIChatData: compareISIChatSlice,
  campaignPerformance: campaignPerformanceSlice.reducer
});

export default configureStore({
  reducer: rootReducers
});
