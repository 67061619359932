import { convertToK, convertToPercent } from "./dataFilter";

export const getInsights = (data) => {
  const temp = {
    spend: 0,
    revenue: 0,
    roi: 0,
    impressions: 0,
    leads: 0,
    sales: 0,
    cpa: 0,
    revenuePerSales: 0
  };

  data.map((ele) => {
    temp.spend += ele.spend;
    temp.impressions += ele.impressions;
    temp.revenue += ele.revenue;
    temp.leads += ele.leads;
    temp.sales += ele.sales;
    return ele;
  });
  temp.roi = ((temp.revenue - temp.spend) / temp.spend) * 100;
  temp.cpa = temp.spend / temp.sales;
  temp.revenuePerSales = temp.revenue / temp.sales;
  return temp;
};

export const groupByChannelAndSumSpend = (data, groupByField) => {
  const spendByChannel = data.reduce((acc, item) => {
    const { spend } = item;
    const groupField = item[groupByField];

    if (!acc[groupField]) {
      acc[groupField] = 0;
    }

    acc[groupField] += spend;
    return acc;
  }, {});

  // Convert the spendByChannel object to an array of objects for sorting
  const sortedData = Object.entries(spendByChannel)
    .map(([channel, spend]) => ({ channel, spend }))
    .sort((a, b) => b.spend - a.spend); // Sort by spend in descending order

  // Separate the sorted data into channels and spend arrays
  const result = {
    labels: sortedData.map((item) => item.channel),
    totalSpend: sortedData.map((item) => item.spend)
  };
  return result;
};

export const columnDataCalculations = (columnDada) => {
  const tempData = columnDada;
  Object.keys(tempData).forEach((item) => {
    tempData[item].totalReturn =
      tempData[item].generatedRevenue - tempData[item].totalSpend;
    tempData[item].roi =
      ((tempData[item].generatedRevenue - tempData[item].totalSpend) /
        tempData[item].totalSpend) *
      100;

    tempData[item].conversionRateLeadsToImpressions =
      tempData[item].leads / tempData[item].totalImpressions;

    tempData[item].conversionRateSalesToImpressions =
      tempData[item].sales / tempData[item].totalImpressions;

    tempData[item].conversionRateSalesToLeads =
      tempData[item].sales / tempData[item].leads;

    tempData[item].costPerImpression =
      tempData[item].totalSpend / tempData[item].totalImpressions;

    tempData[item].costPerLead =
      tempData[item].totalSpend / tempData[item].leads;

    tempData[item].costPerSales =
      tempData[item].totalSpend / tempData[item].sales;

    tempData[item].revenuePerSales =
      tempData[item].generatedRevenue / tempData[item].sales;

    tempData[item].totalReturnPerSales =
      tempData[item].generatedRevenue / tempData[item].sales -
      tempData[item].totalSpend / tempData[item].sales;
  });

  return tempData;
};

export const sortedByTimeSpend = (data, type) => {
  const sortedData = Object.entries(data)
    .map(([, value]) => ({
      ...(type === "channel" && { channel: value.channel }),
      ...(type === "campaign" && { campaignName: value.campaignName }),
      totalSpend: `${convertToK(value.totalSpend)} €`,
      totalImpressions: `${convertToK(value.totalImpressions)} €`,
      generatedRevenue: `${convertToK(value.generatedRevenue)} €`,
      totalReturn: `${convertToK(value.totalReturn)} €`,
      roi: convertToPercent(value.roi),
      leads: `${convertToK(value.leads)} €`,
      sales: `${convertToK(value.sales)} €`,
      conversionRateLeadsToImpressions: convertToPercent(
        value.conversionRateLeadsToImpressions
      ),
      conversionRateSalesToImpressions: convertToPercent(
        value.conversionRateSalesToImpressions
      ),
      conversionRateSalesToLeads: convertToPercent(
        value.conversionRateSalesToLeads
      ),
      costPerImpression: `${convertToK(value.costPerImpression)} €`,
      costPerLead: `${convertToK(value.costPerLead)} €`,
      costPerSales: `${convertToK(value.costPerSales)} €`,
      revenuePerSales: `${convertToK(value.revenuePerSales)} €`,
      totalReturnPerSales: `${convertToK(value.totalReturnPerSales)} €`
    }))
    .sort((a, b) => b.totalSpend - a.totalSpend);

  return sortedData;
};

export const getAllSourcesData = (data) => {
  const summaryByChannel = data.reduce((acc, item) => {
    const { channel, spend, impressions, revenue, sales, leads } = item;

    if (!acc[channel]) {
      acc[channel] = {
        channel,
        totalSpend: 0,
        generatedRevenue: 0,
        totalReturn: 0,
        roi: 0,
        totalImpressions: 0,
        leads: 0,
        sales: 0,
        conversionRateLeadsToImpressions: 0,
        conversionRateSalesToImpressions: 0,
        conversionRateSalesToLeads: 0,
        costPerImpression: 0,
        costPerLead: 0,
        costPerSales: 0,
        revenuePerSales: 0,
        totalReturnPerSales: 0
      };
    }

    acc[channel].totalSpend += spend;
    acc[channel].totalImpressions += impressions;
    acc[channel].generatedRevenue += revenue;
    acc[channel].sales += sales;
    acc[channel].leads += leads;

    return acc;
  }, {});
  const channelWiseData = columnDataCalculations(summaryByChannel);
  const sortedData = sortedByTimeSpend(channelWiseData, "channel");
  return sortedData;
};

export const getCampaignDetailsData = (data) => {
  const summaryByCampaign = data.reduce((acc, item) => {
    const { spend, impressions, revenue, sales, leads } = item;
    const campaignName = item.campaign_name;

    const key = campaignName;

    if (!acc[key]) {
      acc[key] = {
        campaignName,
        totalSpend: 0,
        generatedRevenue: 0,
        totalReturn: 0,
        roi: 0,
        totalImpressions: 0,
        leads: 0,
        sales: 0,
        conversionRateLeadsToImpressions: 0,
        conversionRateSalesToImpressions: 0,
        conversionRateSalesToLeads: 0,
        costPerImpression: 0,
        costPerLead: 0,
        costPerSales: 0,
        revenuePerSales: 0,
        totalReturnPerSales: 0
      };
    }

    acc[key].totalSpend += spend;
    acc[key].totalImpressions += impressions;
    acc[key].generatedRevenue += revenue;
    acc[key].sales += sales;
    acc[key].leads += leads;

    return acc;
  }, {});
  const campaignWiseData = columnDataCalculations(summaryByCampaign);
  const sortedCampaignDetailsData = sortedByTimeSpend(
    campaignWiseData,
    "campaign"
  );
  return sortedCampaignDetailsData;
};
