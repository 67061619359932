/* eslint-disable */

import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { MenuItem } from "@mui/material";
import "./assets/stylesheets/tableStyles.scss";
import { useTranslation } from "react-i18next";
import TableComponent from "./components/TableComponent";
import PopUpWindow from "../../PopUpWindow/components/PopUpWindow";
import SearchBar from "../../inputElements/Input/SearchBar";
import "../GeneralBarGraph/assets/stylesheets/barGraph.scss";
import ChartHeader from "../../ChartHeader/ChartHeader";

export default function Index({
  labels,
  labelHeaders,
  columnData,
  title,
  handleSearch
}) {
  const [togglePopUp, setTogglePopUp] = useState(false);
  const { t } = useTranslation();

  const [filterGear, setFilterGear] = useState(false);
  const [showGear, setShowGear] = useState(false);
  const menuFilterRef = useRef(null);
  const [columns, setColumns] = useState([]);

  const handleChange = (e) => {
    const val = parseInt(e.target.value, 10);

    if (columns.includes(val)) {
      setColumns(columns.filter((ele) => ele !== val));
    } else {
      setColumns([...columns, val]);
    }
  };

  const handleOutsideClick = (event) => {
    if (
      menuFilterRef.current &&
      !menuFilterRef.current.contains(event.target)
    ) {
      setFilterGear(false);
    }
  };

  const handleButtonMouseDown = (event) => {
    // Prevent the mousedown event from reaching the document
    event.stopPropagation();
  };

  useEffect(() => {
    if (filterGear) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [filterGear]);

  useEffect(() => {
    setColumns(columnData.map((ele) => ele.id));
    if (columnData?.length > 1) {
      setShowGear(true);
    }
  }, [columnData]);

  const tableContent = (
    <div className="bargraph-body background-box">
      <ChartHeader
        title={title}
        togglePopUp={togglePopUp}
        setTogglePopUp={setTogglePopUp}
        showGear={showGear}
        filterGear={filterGear}
        setFilterGear={setFilterGear}
        handleButtonMouseDown={handleButtonMouseDown}
      />
      <div className="flex-end">
        <SearchBar
          placeholder={
            title === t("campaignPerformance.allSources")
              ? t("campaignPerformance.searchBySource")
              : t("campaignPerformance.searchByCampaign")
          }
          onSearch={handleSearch}
        />
      </div>
      {filterGear && (
        <div className="menuFilter" ref={menuFilterRef}>
          {columnData?.map((ele, i) => (
            <MenuItem key={i} value={ele.id} onClick={handleChange}>
              <input
                type="checkbox"
                checked={columns.findIndex((column) => column === ele.id) > -1}
                className="custom-checkbox"
                value={ele.id}
                id={`checkbox-${ele.id}`}
                onChange={handleChange}
              />
              <label
                htmlFor={`checkbox-${ele.id}`}
                className="custom-checkbox-label"
              >
                <div className="checkmark" />
                <div className="menuItemList">{ele.name}</div>
              </label>
            </MenuItem>
          ))}
        </div>
      )}
      <TableComponent
        labels={labels}
        labelHeaders={labelHeaders}
        columnData={columnData}
        columns={columns}
        numBars={
          togglePopUp ? labels?.length : labels?.length < 6 ? labels?.length : 6
        }
      />
    </div>
  );
  if (togglePopUp)
    return <PopUpWindow className="stacked-chart">{tableContent}</PopUpWindow>;

  return tableContent;
}

Index.propTypes = {
  labels: PropTypes.oneOfType([PropTypes.array]).isRequired,
  labelHeaders: PropTypes.oneOfType([PropTypes.array]).isRequired,
  columnData: PropTypes.oneOfType([PropTypes.array]).isRequired,
  title: PropTypes.string,
  handleSearch: PropTypes.func
};
