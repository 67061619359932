import axios from "../../../../api/axios";

const getManageApiServises = (payload) =>
  axios.get(`/manageApi/getServiceName/${payload}`);

const manageApiConnection = (payload) =>
  axios.post(`/api/airbyte/create-source-and-connect`, payload);

const updateManageApiConnection = (payload) =>
  axios.post("/api/airbyte/update-connection", payload);

const deleteManageApiConnection = (payload) =>
  axios.post("/api/airbyte/delete-connection", payload);

const manageIsiChatConnection = (payload) =>
  axios.post("/manageApi/addIsiApi", payload);

const updateAsaConnection = (payload) =>
  axios.post("asaManageApi/changePassword", payload);

const deleteAsaConnection = (payload) =>
  axios.post("/asaManageApi/delete", payload);

const asaRegisterApi = (payload) =>
  axios.post("/asaManageApi/register", payload);

export {
  getManageApiServises,
  manageApiConnection,
  updateManageApiConnection,
  deleteManageApiConnection,
  manageIsiChatConnection,
  updateAsaConnection,
  deleteAsaConnection,
  asaRegisterApi
};
